import React, { Component } from "react"
import { Link } from "gatsby"
import Helmet from "react-helmet"
import { withTranslation, I18nextContext } from "gatsby-plugin-react-i18next"
import { setPathSlash } from "./lib/utils";

class ContactForm extends Component {

  static contextType = I18nextContext;
  
  render() {
    const { t } = this.props
    const language = this.context.language || "es"

    return (
      <React.Fragment>
        <Helmet>
          <script>
          {`
            function onSubmit(e) {
              grecaptcha.ready(function() {
                grecaptcha.execute('${this.props.data.siteMetadata.googleReCaptchaAPIKey}', {action: 'submit'}).then(function(token) {
                  var messages = {
                    "EMAIL_SENT":"${t("EMAIL_SENT")}",
                    "ERROR_RETRIEVING_GOOGLE_RECAPTCHA":"${t("ERROR_RETRIEVING_GOOGLE_RECAPTCHA")}",
                    "ERROR_VERIFYING_GOOGLE_RECAPTCHA":"${t("ERROR_VERIFYING_GOOGLE_RECAPTCHA")}",
                    "ERROR_SENDING_EMAIL":"${t("ERROR_SENDING_EMAIL")}",
                  };
                return 	$.ajax({
                          type: "POST",
                          crossDomain: true,
                          url: "${this.props.url}",
                          data: $("#${this.props.id}").serialize(),												
                          success: function(msg){
                            window.addDataLayer({
                                event: "contact_form",
                                form_error: "sin error",
                                form_submit_result: "enviado correctamente"
                            });
                            alert(messages[msg.code]);													
                          },
                          error: function(msg) {
                            window.addDataLayer({
                                event: "contact_form",
                                form_error: msge.code,
                                form_submit_result: "fallo en envío"
                            });
                            alert(messages[msg.code]);													
                          }
                        });
                });
              });
            }
          `}
          </script>
        
          <script src={`https://www.google.com/recaptcha/api.js?render=${this.props.data.siteMetadata.googleReCaptchaAPIKey}&lang=${language}`}></script>
        </Helmet>
        <form
          id={this.props.id}
          className={`contact-form container-central`}
          method="POST"
          action={this.props.url}
          onSubmit={event => {
            window.grecaptcha.execute();
            event.preventDefault();
          }}
        >
          <div className="row">
            <input type="hidden" name="stage" value={this.props.data.siteMetadata.environment} />
            <div className={`form-group col-md-5`}>
              <label htmlFor="nombre-text">
                {t("Nombre")}
                <span>*</span>
              </label>
              <input
                type="text"
                className={`form-control`}
                id="nombre-text"
                name="nombre"
                required
              ></input>
            </div>
            <div className={`form-group col-md-7`}>
              <label htmlFor="email-text">
                {t("Email")}
                <span>*</span>
              </label>
              <input
                type="email"
                className={`form-control`}
                id="email-text"
                name="email"
                required
              ></input>
            </div>
            <div className={`form-group col-md-5`}>
              <label htmlFor="asunto-text">
                {t("Asunto")}
                <span>*</span>
              </label>
              <select
                className={`form-control`}
                id="asunto-text"
                name="asunto"
                required
              >
                {this.props.asuntos &&
                  this.props.asuntos.split("|").map(asunto => {
                    const fieldData = asunto.split("¬")
                    return (
                      <option key={fieldData[1] ? fieldData[1] : fieldData[0]}
                        value={fieldData[1] ? fieldData[1] : fieldData[0]}
                      >
                        {fieldData[0]}
                      </option>
                    )
                  })}
              </select>
            </div>
          </div>
          <div className={`form-group`}>
            <label htmlFor="comentarios-textarea">
              {t("Comentarios")}
              <span>*</span>
            </label>
            <textarea
              className={`form-control`}
              id="comentarios-textarea"
              rows="3"
              name="comentarios"
              required
            ></textarea>
          </div>

          <p
            dangerouslySetInnerHTML={{
              __html: t("Marcados", {
                interpolation: { escapeValue: false },
              }),
            }}
          ></p>
          <div className={`form-group form-check`}>
            <input
              type="checkbox"
              className={`form-check-input`}
              id="acepto-terminos-check"
              required
            ></input>
            <Link
              to={
                setPathSlash(this.props.allLocalizedArticle.edges.find(
                  article =>
                    article.node.articleId ===
                    this.props.data.siteMetadata.termsAndConditionsArticle &&
                    article.node.lang === language
                ).node.path)
              }
            >
              <label
                className={`form-check-label clickable`}
                htmlFor="acepto-terminos-check"
              >
                {t("Acepto los términos y condiciones")}
              </label>
            </Link>
          </div>
          <div
            id="recaptcha"
            className={`g-recaptcha`}
            data-sitekey={this.props.data.siteMetadata.googleReCaptchaAPIKey}
            data-callback="onSubmit"
            data-size="invisible"
          ></div>
          <button id="submit" type="submit" className={`btn btn-primary`}>
            {t("Aceptar")}
          </button>
        </form>
      </React.Fragment>
    )
  }
}

export default withTranslation("ContactForm")(ContactForm)
