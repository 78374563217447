import React, { Component } from "react"
import { withTranslation } from 'gatsby-plugin-react-i18next';
import { StaticQuery, graphql, Link } from "gatsby"
import { setPathSlash } from "./lib/utils";

class Blocks extends Component {
  render() {
	const { t } = this.props;
	const language = this.props.i18n.language;

	return <StaticQuery
		query={graphql`
			query AllLocalizedArticlesForBlock {
				allLocalizedArticle {
					edges {
						node {
							path
							articleId
							lang
							seo_desc
							name
							remoteImage {
								childImageSharp {
									fixed(width: 428, height:170, fit:COVER) {
										src
										srcSet
									}
								}
							}
						}
					}
				}
			}
		`}
		render={data => {
			const cards = this.props.refs.map((code) => {
				const node = data.allLocalizedArticle.edges.find(article => ((article.node.articleId === code) && (article.node.lang === language)));
				if (node) {
					const article = node.node;
					return <div className="col mb-4" key={code}>
						<div className="card">
							<h5 className="card-title">{article.name}</h5>
							<img src={article.remoteImage.childImageSharp.fixed.src}
								srcSet={article.remoteImage.childImageSharp.fixed.srcSet}
								className="card-img-top" alt={article.name} />
							<div className="card-body">
								<p className="card-text block-text mt-3" title={article.seo_desc}>
									{article.seo_desc}
								</p>
								<Link to={setPathSlash(article.path)} className="btn btn-outline-primary btn-sm bi">{t("INFO")}</Link>
							</div>
						</div>
					</div>
				} else {
					return <React.Fragment key={code} />
				}
			});
			return <div className="row row-cols-1 row-cols-lg-2 blocks">{cards}</div>;
		}}/>
	}
}

export default withTranslation('Blocks')(Blocks);
