import React, { Component } from "react"
import { withTranslation } from 'gatsby-plugin-react-i18next';
import { StaticQuery, graphql, Link } from "gatsby"
import { setPathSlash } from "./lib/utils";

class ExperiencesCarousel extends Component {
  render() {
		const { t } = this.props;
		const language = this.props.i18n.language;

    return <div className="index-experiences py-5 text-center container-principal">
        <div className="row experiencias-expanded">
          {this.generateExpandedCarousel(language, t)}
        </div>
      </div>
  }

  generateExpandedCarousel(language, t) {
    return <StaticQuery query={graphql`
          query ExperiencesCarouselQuery {
            site {
              siteMetadata {
                menuLinks {
                  carouselTitle
                  description
                  name
                  image
                  children {
                    experience
                    article
                  }
                }
              }
            }
            allLocalizedArticle(sort: {fields: [section, lang, order], order: ASC}) {
              edges {
                node {
                  articleId
                  name
                  path
                  lang
                  section
                }
              }
            }
          }
        `}
      render={data => (<>
        {data.site.siteMetadata.menuLinks.map(menu => {
          if (menu.children) {
            const thisArticle = data.allLocalizedArticle.edges.find(article => ((article.node.articleId === menu.children[0].article) && (article.node.lang === language)));
            return thisArticle ?
              <Link to={setPathSlash(thisArticle.node.path)} className="col-lg-4 mb-4" key={menu.name}>
                <div>
                  <div style={{ backgroundImage: `url(/${menu.image})` }}>                    
                  <p>{t(menu.carouselTitle)}</p>
                  </div>                  
                  <div>
                    <h3>{t(menu.name)}</h3>
                    <p >{t(menu.description)}</p>
                    <button className={`my-4 btn btn-primary`}>
                      <span>{t("masInfo")}</span>
                    </button>
                  </div>
                </div>
              </Link>
              :
              null;
          } else {
            return null;
          }
        })}
      </>
      )} />;
  }
}

export default withTranslation('IndexExperiences')(ExperiencesCarousel);