import React, {useMemo} from 'react';
import parse, { domToReact } from 'html-react-parser';
import { graphql, Link } from "gatsby"
import Layout from '../components/Layout';
import Sections from '../components/Sections';
import Block from '../components/Block';
import Helmet from "react-helmet";
import ContactForm from '../components/ContactForm';
import Opinions from '../components/Opinions';
import Button from '../components/Button';
import GoogleMap from '../components/GoogleMap';
import ContentContext from "../context/ContentContext";
import { useSiteMetadata } from "../hooks/use-site-metadata"
import {I18nextContext, useTranslation} from 'gatsby-plugin-react-i18next'
import PricedExperience from '../components/PricedExperience';
import Faq from '../components/Faq';
import ContentImage from '../components/ContentImage';
import safe from "../../static/safe.png";
import ExperiencesCarousel from '../components/ExperiencesCarousel';
import { setPathCanonical, setPathSlash } from "../components/lib/utils";
import favicon from '../../static/favicon.svg'
import { useLocation } from "@reach/router";
import ContentFile from '../components/ContentFile';

function nextElementSibling(elem) {
	let node = elem.next;
	while (node !== null && node.type !== "tag") node = node.next;
	return node;
}

export const query = graphql`
	query ArticleDetails($lang: String, $articleId: Int) {
		localizedArticle(lang: {eq: $lang}, articleId: {eq: $articleId}) {
			remoteImage {
				childImageSharp {
					fixed(width: 1110, height:400, fit: COVER, cropFocus: CENTER) {
						src
					}
				}
			}
			siteBaseUrl
			lang
			name
			section
			seo_desc
			slug
			originalPath
			originalSlug
			subtitle
			articleId
			full_text
			seo_title
			translations {
				lang
				path
			}
		}
		site {
			siteMetadata {
				appAPIKey
				statusAPIURL
				googleReCaptchaAPIKey
				termsAndConditionsArticle
				environment
			}
		}
		allLocalizedArticle {
			edges {
			  	node {
					articleId
					lang
					path
				}
			}
		}
	}
`

export default ({ data }) => {
	const { contactFormURL } = useSiteMetadata();
	const { t } = useTranslation();
	const article = data.localizedArticle;
	const location = useLocation();
	const { language } = React.useContext(I18nextContext);
	const replaceOptions = (article) => ({
		trim: true,
		replace: domNode => {
			if (domNode.name === 'img' && domNode.attribs.src && domNode.attribs.src.startsWith('/')) {
				return <ContentImage image={domNode}/>
			} else if (domNode.name === 'a' && domNode.attribs.href && domNode.attribs.href.startsWith('/') && domNode.attribs.href.endsWith('.pdf')) {
				return <ContentFile url={domNode.attribs.href} name={domNode.children[0].data}/>
			} else if (domNode.attribs && (domNode.attribs.class === 'columnas-4' ||domNode.attribs.class === '4-columnas' || domNode.attribs.class === 'matriz-4')) {
				domNode.children.forEach(child => {
					if (!child.attribs) {
						child.attribs = {};
					}
					child.attribs.class = `col-lg-${(child.name === "div" || child.name === "figure") ? 3 : "12 my-4"}`;
				});
				return <div className={(domNode.attribs.class === 'columnas-4' || domNode.attribs.class === '4-columnas') ? "container-principal" : "container-small"}>
					<div className="row text-center columnas-4">
						{domToReact(domNode.children, replaceOptions)}
					</div>
				</div>
			} else if (domNode.attribs && (domNode.attribs.class === 'columnas-3' ||domNode.attribs.class === '3-columnas' || domNode.attribs.class === 'matriz-3')) {
				domNode.children.forEach(child => {
					if (!child.attribs) {
						child.attribs = {};
					}
					child.attribs.class = `col-lg-${(child.name === "div" || child.name === "figure") ? 4 : "12 my-4"}`;
				});
				return <div className={(domNode.attribs.class === 'columnas-3' || domNode.attribs.class === '3-columnas') ? "container-principal" : "container-small"}>
					<div className="row text-center columnas-3">
						{domToReact(domNode.children, replaceOptions)}
					</div>
				</div>
			} else if (domNode.attribs && (domNode.attribs.class === 'columnas-2' || domNode.attribs.class === 'lista-2-col')) {
				domNode.children.forEach(child => {
					if (!child.attribs) {
						child.attribs = {};
					}
					child.attribs.class = "col-lg-6";
				});
				return <div className={`${domNode.attribs.class} container-principal`}>
					<div className="row columnas-2">
						{domToReact(domNode.children, replaceOptions)}
					</div>
				</div>
			} else if (domNode.attribs && domNode.attribs.class === 'destacado') {
				return <div className="destacado mt-5 pb-3">
					<div className="llamita llamita-superior"></div>
					<div className="container-principal">
						{domToReact(domNode.children, replaceOptions)}
					</div>
				</div>;
			} else if (domNode.attribs && domNode.attribs.class === 'button') {
				return <div className="container-central text-center">
					<Button id={domNode.attribs.id} text={domNode.attribs.text} />
				</div>
			} else if (domNode.attribs && domNode.attribs.class === 'faq') {
				const experiencias = domNode.attribs.experiences ? domNode.attribs.experiences.split(",") : null;
				const id = domNode.attribs.id ? domNode.attribs.id.split(",") : null;
				return <Faq experiences={experiencias} id={id} />
			} else if (domNode.attribs && domNode.attribs.class === 'experiences') {
				const ids = domNode.attribs.id ? domNode.attribs.id.split(",") : null;
				const tags = domNode.attribs.tag ? domNode.attribs.tag.split(",") : null;
				const cats = domNode.attribs.cat ? domNode.attribs.cat.split(",") : null;
				return <div className="container-principal">
					<PricedExperience article={article} id={ids} cat={cats} tag={tags} />
				</div>;
			} else if (domNode.attribs && domNode.attribs.class === 'experiencies-carousel') {
				return <ExperiencesCarousel />;
			} else if (domNode.attribs && domNode.attribs.class === 'block') {
				return <Block refs={domNode.attribs.refs.split(',').map(x=>+x)} />;
			} else if (domNode.attribs && domNode.attribs.class === 'sections') {
				return <Sections refs={domNode.attribs.refs.split(',').map(x=>+x)} />;
			} else if (domNode.attribs && domNode.attribs.class === 'info-contacto') {
				const sibling = nextElementSibling(domNode);
				if (sibling && sibling.attribs.class === 'info-contacto') {
					return (<div className="row">
						<div className="info-contacto col-12 col-lg-6">
							{domToReact(domNode.children, replaceOptions)}
						</div>
						<div className="info-contacto col-12 col-lg-6">
							{domToReact(domNode.next.next.children, replaceOptions)}
						</div>
					</div>);
				} else {
					return <React.Fragment/>
				}
			} else if (domNode.attribs && domNode.attribs.class === 'contact-form') {
				return <ContactForm id="contact-form" asuntos={domNode.attribs.asuntos} url={contactFormURL} data={data.site} allLocalizedArticle={data.allLocalizedArticle} ></ContactForm>
			} else if (domNode.attribs && domNode.attribs.class === 'contact-page-map') {
				return <div className="container-principal">
					<GoogleMap lat={parseFloat(domNode.attribs.lat.replace(",", "."))} lng={parseFloat(domNode.attribs.lng.replace(",", "."))}></GoogleMap>
				</div>
			} else if (domNode.name === "table") {
				return <div className="container-principal">
					<div className="table-responsive">
						<table className="table table-bordered">
							{domToReact(domNode.children, replaceOptions)}
						</table>
					</div>
				</div>
			} else if (domNode.attribs && domNode.attribs.class === 'btn-cta') {
				return 	<div className={`text-center`}>
					<Link to={setPathSlash(domNode.attribs.href)} className="btn btn-primary btn-movil">{t("Registrar empresa colaboradora")}</Link>
				</div>
			} else if (domNode.name === "ul") {
				if (!domNode.attribs) {
					domNode.attribs = {};
				}
				if (!domNode.attribs.class) {
					domNode.attribs.class = "";
				}
				domNode.attribs.class += ` ${article.originalSlug === 'politica_de_cookies'
										  || article.originalSlug === 'terminos_y_condiciones'
				                          || article.originalSlug === 'politica_de_privacidad'  
										  || article.originalSlug === 'aviso_legal'
										  ? 'container-principal' 
										  : 'container-central'}`;
				domNode.children.forEach(child => {
					if (child.children && child.children.find(childChild => childChild.name === 'strong')) {
						if (!child.attribs) {
							child.attribs = {};
						}
						if (!child.attribs.class) {
							child.attribs.class = "";
						}
						child.attribs.class += " li-strong";
					}
				});
			} else if (!domNode.parent && domNode.name !== "ul" && (!domNode.attribs || domNode.attribs.class !== 'destacado')) {
				if (!domNode.attribs) {
					domNode.attribs = {};
				}
				if (!domNode.attribs.class) {
					domNode.attribs.class = "";
				}
				domNode.attribs.class += " container-principal";
			}
		}
	});
	const memoizedValue = useMemo(() => ({ translations: article.translations }), [article.translations]);
	return(
		<ContentContext.Provider value={memoizedValue}>
			<Layout>
				<Helmet>
					<html lang={language}/>
					<title>{article.seo_title}</title>
					<link rel="icon" href={favicon}/>
					<meta name="title" content={article.seo_title}/>
					<meta name="description" content={article.seo_desc}/>
					<meta name="og:title" content={article.seo_title}/>
					<meta name="og:description" content={article.seo_desc}/>
					{article.remoteImage &&
						<meta name="og:image" content={article.siteBaseUrl + article.remoteImage?.childImageSharp.fixed.src}/>}
					<meta name="og:type" content="article"/>
					<meta name="og:url" content={setPathSlash(article.siteBaseUrl + "/" + article.lang + article.originalPath)}/>
					{article.translations.map((translation) => <link rel="alternate" key={translation.lang}
																													 hrefLang={translation.lang}
																													 href={setPathSlash(article.siteBaseUrl + translation.path)}/>)}
					<link rel="canonical" href={setPathCanonical(location.href)}/>
				</Helmet>
				<article className={`articulo page-${article.originalSlug}`}>
					<div className="container-principal text-center">
						{article.originalSlug === 'medidas_covid19' &&
							<div className="position-relative my-5 height-5"><div className="escudo"></div></div>
						}
						<h1 className='text-red font-weight-light'>{article.originalSlug === 'medidas_covid19' && <img className="safe" src={safe} alt={t("safe")}></img>}{article.name}</h1>
						{typeof (article.subtitle) === "string" ? parse(article.subtitle) : ""}
					</div>
					{typeof (article.full_text) === "string" ? parse(article.full_text,  replaceOptions(article)) : ""}
					{(article.originalSlug === 'excursiones_privadas' || article.originalSlug === 'vip_tour' || article.originalSlug === 'jeep_safari') &&
						<Opinions />
					}
				</article>
			</Layout>
		</ContentContext.Provider>
)};
