import React, { Component } from "react";
import { StaticQuery, graphql, Link } from "gatsby"
import { withTranslation } from 'gatsby-plugin-react-i18next';
import { parseCurrency, setPathSlash } from "./lib/utils";
import _intersection from 'lodash/intersection';
import _isEmpty from 'lodash/isEmpty';
import {onSelectItemDL, onViewItemListDL} from "./lib/dataLayer";

function InfoPill({t, tags, locale}) {
  const validTags = ['pill_novelty','pill_not_available'];
  const availableTags = _intersection(validTags, tags);

  if (_isEmpty(availableTags)) {
    return null;
  } else {    
    return <div className="info-pill" style={{backgroundImage: `url("/info-pills/`+availableTags[0]+`-`+locale+`.png")`}} title={t("tags_" + availableTags[0])}></div>
  }  
}

class PricedExperienceList extends Component {

  componentDidMount() {
    onViewItemListDL(this.props.experiences);
  }

  generateExperience(t, i, article, experience, language, className, onClick) {
    return <Link to={setPathSlash(experience.node.path)} className={`${className} my-3 text-decoration-none`} key={`PricedExperience-${i}`} onClick={() => onClick(experience, i) }>
      <div className="mini-experience">
        <InfoPill t={t} tags={experience.node.tags} locale={experience.node.lang} />
        <div className="background" style={{backgroundImage: `url(${experience.node.image ? experience.node.image.url : experience.node.images[0].url})`}} title={experience.node.image ? experience.node.image.alt : experience.node.images[0].alt}></div>
        <h3 className="p-2 m-0">{experience.node.short_name}</h3>
        <div className="footer">
          <span className="p-2">{t("duracion")}: {experience.node.duration.hours}:{experience.node.duration.minutes}{experience.node.duration.minutes === 0 ? '0' : ''}</span>
          <div></div>
          {experience.node.lowestRate && 
            <span className="p-2">{t("desde")} {parseCurrency(language ,experience.node.lowestRate.value, experience.node.lowestRate.currency)}</span>
          }
        </div>
      </div>
    </Link>
  }

  render() {
    const t = this.props.t;
    const experiences = this.props.experiences || [];
    const onClick = this.props.onClick;
    const language = this.props.language;
    const article = this.props.article;

    const className = experiences.length >= 3 ? 'col-lg-4' : 'col-lg-6';

    return <React.Fragment>
      {experiences.map((experience, index) => this.generateExperience(t, index, article, experience, language, className, onClick))}
    </React.Fragment>
  }
}

class PricedExperience extends Component {
  render() {
    const { t } = this.props;
    const language = this.props.i18n.language;
    const article = this.props.article;
    const checker = (arr, target) => target.every(v => arr.includes(v));

    return <div className="row justify-content-center">
      <StaticQuery query={graphql`
        query PricedExperienceQuery {
            allExperience {
              edges {
                node {
                  experienceId
                  name
                  name_spa
                  short_name
                  path
                  lang
                  subcategory{
                    id
                    name_spa
                  }
                  subcategories
                  tags
                  duration {
                    hours
                    minutes
                    formatted
                  }
                  lowestRate {
                    formatted
                    currency
                    value
                  }
                  image {
                    url
                    alt
                    title
                  }
                  images {
                    url
                    alt
                    title
                  }
                  languages
                  cancelled
                  products {
                    id
                    name
                    name_spa
                    weekDays
                  }
                }
              }
            }
          }
      `}

       render = {
        data => {

         const experiences = [];
         (data.allExperience.edges.forEach((experience) => {
           if (this.props.id) {
             this.props.id.forEach(id => {
               if (experience.node.experienceId === Number(id.replace('"', '').replace(',', '').trim()) && experience.node.lang === language) {
                 experiences.push(experience);
               }
             })
           }
           if (this.props.cat) {
             let catsFine = [];
             this.props.cat.forEach(cat => {
               catsFine.push(cat.replace('"', '').replace(',', '').trim());
             });
             catsFine = catsFine.filter(cF => cF);
             if (checker(experience.node.subcategories, catsFine) && experience.node.lang === language) {
               experiences.push(experience);
             }
           }
           if (this.props.tag) {
             let tagsFine = [];
             this.props.tag.forEach(tag => {
               tagsFine.push(tag.replace('"', '').replace(',', '').trim());
             });
             tagsFine = tagsFine.filter(tF => tF);
             if (checker(experience.node.tags, tagsFine) && experience.node.lang === language) {
               experiences.push(experience);
             }
           }
         }));

         return <PricedExperienceList
             t={t}
             onClick={onSelectItemDL}
             experiences={experiences}
             article={article}
             language={language}
         />;
       }
     }/>
    </div>
  }
}
export default withTranslation('PricedExperience')(PricedExperience);
