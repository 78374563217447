import React, { Component } from "react"
import { withTranslation } from 'gatsby-plugin-react-i18next';
import { StaticQuery, graphql, Link } from "gatsby"
import { setPathSlash } from "./lib/utils";

class Sections extends Component {
	
	render() {
		const { t } = this.props;
		const language = this.props.i18n.language;

		function renderLink(article) {
			return 	<div className="tamaran-section card border-white">
						<div className="card-body">
							<Link to={setPathSlash(article.path)} className={`text-decoration-none`}>
								<span className={`icon-section-${article.originalSlug}`}></span>
								<h5 className="card-title">{article.name}</h5>
							</Link>
						</div>
						<div className="card-footer">
							<Link to={setPathSlash(article.path)} className={`text-decoration-none`}>
								<span className="btn btn-outline-primary btn-sm bi">{t("INFO")}</span>
							</Link>
						</div>
					</div>
		}

		return <StaticQuery
			query={graphql`
				query AllLocalizedArticlesAndExperiencesForSections {
					allLocalizedArticle {
						nodes {
							path
							originalSlug
							articleId
							lang
							name
						}
					}
					allExperience {
						nodes {
							path
							experienceId
							lang
						}
					}
				}
			`}
			render={data => {
				const cards = this.props.refs.map((code) => {
					const article = data.allLocalizedArticle.nodes.find(article => ((article.articleId === code) && (article.lang === language)));
					if (article) {
						return 	<React.Fragment key={code}>
									{renderLink(article)}
								</React.Fragment>
					}
					const experience = data.allExperience.nodes.find(experience => ((experience.experienceId === code) && (experience.lang === language)));
					if (experience) {
						const article = {
							name: t("NavigationBar:Reservar"),
							originalSlug: 'reservar',
							...experience,
						}
						return 	<React.Fragment key={code}>
									{renderLink(article)}
								</React.Fragment>
					}
					return <React.Fragment key={code}/>
				});
				return <div className="pt-3 pb-5">
					{this.props.message &&
							<div className="pb-5 footer-secciones">
								<hr />
								<h4>{t(this.props.message)}</h4>
							</div>
					}
					<div className="card-deck text-center">
						{cards}
					</div>
				</div>
			}} />
	}
}

export default withTranslation(['Sections','NavigationBar'])(Sections);
