import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"

export default class ContentFile extends Component {
  render() {

	return <StaticQuery
			query={graphql`
			query contentResource {
				allFile(filter: {url: {regex: "^https.*/g"}, ext: {eq: ".pdf"}}) {
					nodes {
					  url
					  publicURL
					}
				}
				site {
			    	 siteMetadata {
			        volcanoTeideAPIConfig {
			          protocol
			          host
			        }
			      }
			    }
			}
		`}
		render={data => {
			const fileSrc = new URL(
				`${data.site.siteMetadata.volcanoTeideAPIConfig.protocol}://${data.site.siteMetadata.volcanoTeideAPIConfig.host}`
				+ this.props.url
			).toString();
			const fileNode = data.allFile.nodes.find(file => (file.url === fileSrc));
			if (fileNode) {
				return <a href={fileNode.publicURL} target="_blank" rel="noreferrer">{this.props.name}</a>
			}
		}}	/>
	}
}
